b2c-cart{
    .cart{
        &__list {
            /*background: map-get($map: $mat-accent, $key: 200);*/
            background: rgba($color: map-get($map: $mat-accent, $key: 200), $alpha: 0.5);
            @include decoration-ear($color: 'accent', $item-y: 1, $opacity: 0.2, $width: 300px, $position: 'bottom-left', $space: 65px,);
        }

        &__subtotal{
            &__content{
                background: transparent;
            }
            &__label {
                color: $secondary;
            }
        }
        &__item{
            &__head{
                button{
                    color: $accent;
                    text-decoration: underline;
                    font-weight: 500;
                    padding: 0;
                }
            }
        }


    }

    
}