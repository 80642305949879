/*
*  This is the list of all widget overrides
*/

@import 'mat-accordion-wrapper';
@import 'breadcrumb';
@import 'alert';
@import 'list-wrapper';
@import 'address-list';
@import 'badge';
@import 'chips';

// TODO: LIB (@saep-ict/angular-core) - Aggiornare le proprietà del tab-wrapper
tab-wrapper {
	.header {
		margin: 0 !important;
	}
	.main {
		padding: 0 !important;
		margin: 0 !important;
	}
}
