app-software{
    button.btn-secondary{
        background: $secondary;
        color: #fff;
        margin-top: 1rem;
    }
    .mat-form-field-flex{
        background: #fff;
    }
        .custom-toolbar{
            font-size: 0.9rem;
    
            &__title{
                font-weight: 600;
            }
        }
    
    
}