app-root {
	.mat-toolbar {
		background: transparent;
		color: $dark;

		// lo style seguente applica nel caso venga usata mat-button-toggle-group come switch in mat-toolbar
		.mat-button-toggle-group {
			@include font-size($font-size-default-map);
		}

		&:first-child {
			margin-top: 0;
		}
	}
	.mat-toolbar-row,
	.mat-toolbar-multiple-rows {
		height: auto;

		h1 {
			line-height: 1.2;
		}
	}

	.mat-toolbar-row {
		min-height: 40px;
		white-space: normal;

		.info-container {
			.title-container {
				.title {
					@include font-size($font-size-card-title);
					margin-right: 5px;
				}
				margin-bottom: 10px;
			}
			.subtitle {
				@include font-size($font-size-card-subtitle);
				margin-top: -10px;
				margin-bottom: -10px;
			}
		}
		&.subtitle {
			@include font-size($font-size-card-subtitle);
			opacity: 0.5;
		}
	}
}

.mat-card-subtitle {
	line-height: 1.2;
	font-weight: 400;
}
