.mat-form-field-appearance-outline {
    .mat-form-field-outline-start {
        display: none;
    }
    .mat-form-field-outline-end {
        border-radius: 999px;
        border-left-style: solid;
    }
    .mat-form-field-flex {
        padding: 0 $gutter*2;
    }
}