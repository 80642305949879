b2c-checkout-article-list{
    .card-price{
        background: transparent;
        padding: 1rem 1rem 1rem 0.5rem;

        h3{
            font-weight: 600;
            color: $secondary;
        }
    }
}