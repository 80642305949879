person-card{
    .mat-card-header{
        background: #fff;
    }

    .installer{
        &__label{
            font-weight: 600;
        }
        &__piva::before{
            content: 'P.IVA ';
        }

        &__status{
            display: flex;
            align-items: end;
            justify-content: center;

            .mat-icon{
                color: green;
            }
        }
    }
}