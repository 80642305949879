b2c-catalog {
	.list-wrapper {
		margin: 0;

		.mat-drawer-container {
			position: relative;
			background-color: #fff;
		}
	}
}

app-b2c-organization-detail {
	.tab-wrapper {
		.mat-drawer-container {
			position: relative;
		}
	}
}
