.layout-ctx-public {
	th.td-data-table-column {
		color: $secondary;

		&.mat-active,
		&.mat-active mat-icon {
			color: map-get($map: $mat-secondary, $key: 800);
		}
	}

	tr {
		&.td-data-table-column-row,
		&.td-data-table-row {
			border-bottom-color: rgba($secondary, 50%);
			background: transparent;
		}
	}

	thead {
		tr {
			&.td-data-table-column-row,
			&.td-data-table-row {
				border-bottom-color: transparent;
			}
		}
	}
}
