b2c-catalog {
	.mat-accordion {
		//sidebar style
		.mat-expansion-panel {
			padding-bottom: 1rem;
			box-shadow: none;
		}

		.mat-expansion-panel-header,
		.mat-checkbox {
			width: 100%;
			padding-bottom: 1rem;
			border-radius: 0;
		}
		.mat-checkbox {
			margin-bottom: 1rem;
		}
	}
}
