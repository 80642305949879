.viewtype-toggle{
    color: $secondary;

	.mat-button-toggle {
		opacity: 0.5;
        color: $secondary;
        background: transparent;

		&.mat-button-toggle-checked {
			opacity: 1;

            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 24px;
                height: 2px;
                background: $secondary;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }

			&:hover {
				opacity: 1;
			}
		}

		&:hover {
			opacity: 0.75;
		}
	}
}