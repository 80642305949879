/*
*  This file contains only mixins to handle media query 
*/

/// respond-above
/// @output utility class to handle media queries over a breakpoint
/// @example @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
	// If the breakpoint exists in the map.
	@if map-has-key($map-breakpoints, $breakpoint) {
		// Get the breakpoint value.
		$breakpoint-value: map-get($map-breakpoints, $breakpoint);

		// Write the media query.
		@media (min-width: $breakpoint-value) {
			@content;
		}

		// If the breakpoint doesn't exist in the map.
	} @else {
		// Log a warning.
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

/// respond-below
/// @output utility class to handle media queries below a breakpoint
/// @example @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
	// If the breakpoint exists in the map.
	@if map-has-key($map-breakpoints, $breakpoint) {
		// Get the breakpoint value.
		$breakpoint-value: map-get($map-breakpoints, $breakpoint);

		// Write the media query.
		@media (max-width: ($breakpoint-value - 1)) {
			@content;
		}

		// If the breakpoint doesn't exist in the map.
	} @else {
		// Log a warning.
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

/// respond-between
/// @output utility class to handle media queries between two breakpoint
/// @example @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
	// If both the lower and upper breakpoints exist in the map.
	@if map-has-key($map-breakpoints, $lower) and map-has-key($map-breakpoints, $upper) {
		// Get the lower and upper breakpoints.
		$lower-breakpoint: map-get($map-breakpoints, $lower);
		$upper-breakpoint: map-get($map-breakpoints, $upper);

		// Write the media query.
		@media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
			@content;
		}

		// If one or both of the breakpoints don't exist.
	} @else {
		// If lower breakpoint is invalid.
		@if (map-has-key($map-breakpoints, $lower) == false) {
			// Log a warning.
			@warn 'Your lower breakpoint was invalid: #{$lower}.';
		}

		// If upper breakpoint is invalid.
		@if (map-has-key($map-breakpoints, $upper) == false) {
			// Log a warning.
			@warn 'Your upper breakpoint was invalid: #{$upper}.';
		}
	}
}
