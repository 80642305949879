@import 'var';

// mat-select bianco
.light {
	// Not Focused
	&.mat-form-field {
		.mat-form-field-wrapper {
			.mat-form-field-flex {
				.mat-form-field-infix {
					.mat-select {
						.mat-select-trigger {
							.mat-select-value {
								// colore della label
								color: #fff;
							}
							.mat-select-arrow-wrapper {
								.mat-select-arrow {
									// colore della freccetta
									color: rgba(#fff, 0.7);
								}
							}
						}
					}
				}
			}
			.mat-form-field-underline {
				// colore della linea di base senza focus
				background-color: rgba(#fff, 0.7);
				.mat-form-field-ripple {
					// colore della linea di base con focus (nascosta)
					background-color: rgba(#fff, 0.7);
				}
			}
		}
	}

	// Focused
	&.mat-form-field.mat-focused {
		.mat-form-field-wrapper {
			.mat-form-field-flex {
				.mat-form-field-infix {
					.mat-select {
						.mat-select-trigger {
							.mat-select-value {
								// colore della label (nascosta dalla tendina)
								color: #fff;
							}
							.mat-select-arrow-wrapper {
								.mat-select-arrow {
									// colore della freccetta con focus
									color: #fff;
								}
							}
						}
					}
				}
			}
			.mat-form-field-underline {
				// colore della linea di base con focus (nascosta dalla tendina)
				background-color: #fff;
				.mat-form-field-ripple {
					// colore della linea di base con focus (visibile)
					background-color: #fff;
				}
			}
		}
	}
}
