app-dialog-order-detail{
    .dialog-order-detail{
        &__essay{
            background: rgba($color: map-get($map: $mat-accent, $key: 200), $alpha: 0.5);
        }
        &__main-data{
            h3{
                color: $secondary;
            }
        }
        .card-price{
            background: transparent;
        }
    }

    .price-label{
        font-weight: 600;
        color: $secondary;
    }
}
