ms-room-detail {
    .toolbar-custom{
        padding: 1rem;
        font-size: 1rem;
    }
    .wrapper{
        padding: 0 8px;
        &__card-wrapper {
            margin: 0px 3rem 0 0px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }
        &__label {
            font-weight: 600;
        }
        &__info {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid gray;
        }
        span {
            color: gray;
        }
    }

}
