// $background: map-get($map: $mat-accent, $key: 200);
$background: rgba($color: map-get($map: $mat-accent, $key: 200), $alpha: 1);
b2c-catalog {
    display: block;
    background: $background;
	@include decoration-ear($color: 'accent', $item-x: 2, $item-y: 4, $opacity: 0.2, $width: 400px, $space: 75px, $position: 'bottom-left',);
    .list-wrapper__b2c-catalog {
        .mat-drawer {
            background: $background;
            color: $secondary;
            font-weight: 600;
            margin-top: 140px;
            @media(min-width: 1200px) {
                background: transparent;
            }
            .btn-sidebar{
                &__cancel{
                    color: $accent;
                    text-decoration: underline;
                }

            }
        }
        .mat-drawer-backdrop.mat-drawer-shown {
            background-color: rgba($background, 60%);
        }
        .mat-drawer-side.mat-drawer-end {
            border-left: none;
        }
        mat-icon {
            color: $secondary;
        }
        // mat-select
        .mat-form-field-appearance-outline {
            .mat-form-field-outline-end {
                border: 1px solid $secondary;
            }
        }       
        .mat-select-value {
            color: $secondary;
            padding-bottom: 0.5rem;
        }
        //mat-paginator
        .mat-paginator{
            margin-top: 1rem;
            
            .mat-paginator-range-label {
                order: 2;
            }
        
            .mat-paginator-navigation-previous {
                order: 1;
            }
            .mat-paginator-navigation-next {
                order: 3;
            }
        }
        
        .mat-paginator-page-size-select{
            border: 1px solid $secondary;
            border-radius: 55px;
            width: 100px;

            .mat-form-field-wrapper{
                padding: 0 20px 5px 20px;
            }

            .mat-form-field-underline{
                display: none;
            }

            
        }
        //Filtro per categoria
        .mat-expansion-panel-header,
        .mat-checkbox{
            border-bottom: 1px solid $secondary;
        }
        .mat-expansion-panel-header{
            font-weight: 600;
        }

        //Filtro prezzo
        .mat-select-arrow-wrapper{
            vertical-align: baseline;
        }
       
    }
    .mat-sidenav{
        .ngx-slider .ngx-slider-pointer,
        .ngx-slider .ngx-slider-selection{
            background: $secondary;
        }
    }
}
