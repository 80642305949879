.pre-header {
	background: #000000;
    .mat-select-value, .mat-select-arrow, .mat-option, .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
        color: inherit;
    }
}
.header {
    .mat-form-field-appearance-outline {
        .mat-form-field-outline-end {
            border: 1px solid $primary;
        }
    }
    mat-icon {
        color: $primary;
    }
}