app-plant-detail {
    .card-wrapper{
        display: flex;
         &__item{
             text-align: center;
             background: gray;
             color: white;
             margin: 1rem;
             padding: 0.5rem 2rem;
         }
        &__title{
            font-weight: 600;
        } 
    }
}