.mat-accordion {
	.mat-expansion-panel {
		box-shadow: 0 0 0 transparent;
		border-radius: 0;
        background: transparent;
		.mat-expansion-panel-header {
			border-bottom: 1px solid $secondary;

			.mat-expansion-panel-header-title,
			.mat-expansion-panel-header-description,
			.mat-expansion-indicator::after {
				color: $secondary;
			}
		}
	}
}
