.homepage__entry-section {
	@include decoration-ear($color: 'accent', $position: 'bottom-right', $item-x: 3);
}

.entry-description {
	padding: 3rem 0;

	&__title,
	&__paragraph {
		max-width: 800px;
	}

	&__title {
		font-size: 2rem;
		line-height: 1.5;
	}

	&__paragraph {
		font-size: 1.2rem;
		line-height: 1.5;
	}
}

.map {

	margin: 5rem 0;

	&__content,
	&__info {
		box-shadow: 0 0 100px map-get($map: $mat-primary, $key: 100);
	}

	&__info {
		text-align: left;
		background: map-get($map: $mat-primary, $key: 50);
		border: 4px solid map-get($map: $mat-primary, $key: 100);

		@include decoration-ear($width: 200px, $item-x: 1, $item-y: 1, $space: 0px);
	}
}

.highlight-products {
	background: map-get($map: $mat-accent, $key: 200);
	@include decoration-ear($width: 300px, $color: 'accent', $opacity: 0.2);

	.card{
		&__header{
			padding-top: 1.5rem;
		}

		&__title{
			text-align: start;
		}
	}
}

.highlight-categories{
	background: rgba($color: map-get($map: $mat-accent, $key: 200), $alpha: 0.5);
}