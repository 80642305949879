@import 'var';

mat-tab-group {
	&.main-page {
		margin: 0 -#{$gutter/2};
		.mat-tab-body-wrapper {
			padding: 0 $gutter/2;
		}
	}
    .mat-tab-body-content {
        overflow: hidden;
    }
}
