@mixin decoration-ear(
	$width: 140px,
	$color: 'primary',
	$opacity: 0.1,
	$item-x: 2,
	$item-y: 2,
	$space: 24px,
	$position: 'bottom-left'
) {
	// Based on aspect ratio of the image
	$height: $width * 1.8;

	overflow: hidden;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		display: block;
		background-image: url("/assets/theme-current/img/icon/molino-rachello-pittogramma-" + #{$color} + ".svg");
		transform: rotate(-45deg);
		z-index: 0;
		opacity: $opacity;
		background-repeat: space;
		width: $width * $item-x;
		height: $height * $item-y;
		background-size: ($width - $space) ($height - $space);

		// Handle positions
		@if $position == 'bottom-left' {
			bottom: -$height / 10;
			left: 15%;
		} @else if $position == 'bottom-right' {
			bottom: -$height / 10;
			right: 15%;
		}
	}

	& > * {
		position: relative;
		z-index: 1;
	}
}