@import 'var';

.mat-icon {
	&--16 {
		width: 1.14rem !important;
		height: 1.14rem !important;
		font-size: 1.14rem !important;
	}
	&--32 {
		width: 2.28rem !important;
		height: 2.28rem !important;
		font-size: 2.28rem !important;
	}

	&.warn {
		color: $warn;
	}

	&.success {
		color: $success;
	}
}
