//$app-b2c-organization-detail-background: #fff
//  map-get(
//  	$map: $mat-accent,
//  	$key: 200
//  )
//;

app-b2c-organization-detail {


	.page-header {
		margin-bottom: 0;
		background-image: linear-gradient(181deg, rgba(253,244,227,1) 35%, rgba(255,255,255,1) 100%) !important;

		&__title,
		&__breadcrumbs {
			color: $dark;
			text-align: left;
		}
		.separator:after {
			background: $dark;
		}
	}

	// Dettaglio
	.tab-wrapper {

		padding-top: 1rem;

		// &,
		// & .header {
		// 	background: $app-b2c-organization-detail-background;
		// }

		& .main{
			@include decoration-ear($color: 'accent', $item-x: 2, $item-y: 1, $opacity: 0.2, $width: 200px, $space: 50px, $position: 'bottom-right');
			
		}

		.header {
			li {
				border-color: $secondary;
				color: $secondary;
				text-transform: capitalize !important;

				
				.mat-icon{
					color: $secondary;
				}

				&:hover,
				&.active {
					background: transparent;
				}
			}
		}
	}

	.general-info {

		&__full-data{
			padding-top: 2rem;

		}
		&__illustration {
			display: none;
		}
		&__data {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	// Ordini
	.mat-card {
		background: transparent;
	}

	// Dati utente
	// person-card {
	// 	.mat-card-header .img-icon {
	// 		//background: $app-b2c-organization-detail-background;
	// 	}
	// }
}
