.footer {
	background: $primary;
	color: map-get($map: $mat-primary, $key: 50);

	&__logo {
		.logo-container {
			min-height: 80px;
		}
	}
}
