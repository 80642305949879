/*
* STANDARD FUNCTION STRUCTURE
*
* /// name - description
* /// @param {type} $param [default] - description
* /// @return {type} - description
* @mixin name($param){
* 	// code here
* }
*/

/// set-contrast - set black or white based on lightness of the color
/// @param {string} $color - color reference
/// @return {string} - the color
@function set-contrast($color) {
	@if (lightness($color) > 60) {
		@return $dark; // Lighter backgorund, return dark color
	} @else {
		@return $light; // Darker background, return light color
	}
}

/// createPaletteColor
/// @param {string} $color - color reference
/// @param {string} $name - the name of the palette
/// @return {Map} - the palette color
/// Based on http://mcg.mbitson.com/
@function createPaletteColor($color, $name) {
	$name: (
		50: lighten($color, 52%),
		100: lighten($color, 37%),
		200: lighten($color, 26%),
		300: lighten($color, 12%),
		400: lighten($color, 6%),
		500: $color,
		600: darken($color, 6%),
		700: darken($color, 12%),
		800: darken($color, 18%),
		900: darken($color, 24%),
		contrast: (
			50: set-contrast(lighten($color, 52%)),
			100: set-contrast(lighten($color, 37%)),
			200: set-contrast(lighten($color, 26%)),
			300: set-contrast(lighten($color, 12%)),
			400: set-contrast(lighten($color, 6%)),
			500: set-contrast($color),
			600: set-contrast(darken($color, 6%)),
			700: set-contrast(darken($color, 12%)),
			800: set-contrast(darken($color, 18%)),
			900: set-contrast(darken($color, 24%))
		)
	);

	@return $name;
}

/// Map deep get - Studiato per sostituire il mat-contrast
/// @param {Map} $map - Map
/// @param {Arglist} $keys - Key of the map that contain a map
/// @param {Arglist} $subkeys - Key of the sub-map
/// @return {*} - Desired value
@function map-deep-get($map, $key, $subkey) {
	@return map-get(map-get($map, $key), $subkey);
}
