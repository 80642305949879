@import 'var';

// $not-selected: $gray;
// $selected: $primary;
// $background: #fff;
$not-selected: $gray;
$selected: $secondary;
$background: #fff;

// theme : white
// Used in dialog order detail (b2c)
#theme-white {
	.status-bar {
		background-color: $background;

		.status-bar__step {
			&__icon {
				border: 2px solid;
				border-color: $not-selected;
				color: $not-selected;
				background-color: $background;
				border-radius: 21px;
				width: 90px;
				height: 35px;

				&.selected {
					border-color: $selected;
					background-color: $background;
					color: $selected;
				}
			}

			&__label {
				color: $not-selected;
				padding: 0 1rem;

				&.selected {
					color: $selected;
				}
			}

			// if expanded
			&:not(:only-child) {
				// line
				&:before {
					background-color: $not-selected;
				}
			}

			&.selected {
				color: $selected;

				// if expanded
				&:not(:only-child) {
					// line
					&:before {
						background-color: $selected;
					}
				}
			}
		}
	}
}
