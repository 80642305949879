// @import 'var';

.badge {
	vertical-align: 4px;
	border-radius: 10px;
	padding: 0 8px;
	border: 1px solid $gray;
	background-color: white;
	color: $gray;
	font-weight: 500;
	@include font-size($font-size-sm-map);
}

.badge--rating,
.badge--rating--32 {
	display: inline-block;
	border: 1px solid $dark;
	text-align: center;
}

.badge--rating {
	width: 24px;
	height: 24px;
	border-radius: 24px;
	line-height: 24px;
	font-size: 0.7rem;
}
.badge--rating--32 {
	width: 32px;
	height: 32px;
	border-radius: 32px;
	line-height: 32px;
}

.badge-light {
	background: transparent;
	border-color: $light;
	color: $light;
}
