@charset 'utf-8';
/*
* styles.scss
* File principale usato dal progetto che importa i parziali
*/

@import '~@angular/material/theming';
@import '~@covalent/core/theming/all-theme';
@import '~@covalent/markdown/markdown-theme';
@import '~@covalent/highlight/highlight-theme';
//@import '~@saep-ict/media-center/media-center-theme';
@import '~@saep-ict/angular-core/angular-core-theme';

// il file var è generato grazie allo stylePreprocessorOptions
// a partire dal file _var presente nella cartella del tema usato
@import 'var';

// global-colors contiene le custom properties usate anche da typescript
@import './styles/global-colors';

// custom typography: gestione del font e delle dimensioni usate dal tema material
// Defaults come from `frontend/node_modules/@angular/material/_theming.scss`
// Material spec: 1rem = 14px
$custom-typography: mat-typography-config(
	$font-family: $font-family-name,
	$display-4: mat-typography-level(8rem, 8rem, 300, $letter-spacing: -0.05em),
	$display-3: mat-typography-level(4rem, 4rem, 400, $letter-spacing: -0.02em),
	$display-2: mat-typography-level(3.21rem, 3.42rem, 400, $letter-spacing: -0.005em),
	$display-1: mat-typography-level(2.42rem, 2.85rem, 400),
	$headline: mat-typography-level(1.71rem, 2.28rem, 400),
	$title: mat-typography-level(1.43rem, 2.28rem, 500),
	$subheading-2: mat-typography-level(1.14rem, 2rem, 400),
	$subheading-1: mat-typography-level(1.07rem, 1.71rem, 400),
	$body-2: mat-typography-level(1rem, 1.71rem, 500),
	$body-1: mat-typography-level(1rem, 1.43rem, 400),
	$caption: mat-typography-level(0.8rem, 1.43rem, 400),
	$button: mat-typography-level(1rem, 1rem, 500),
	$input: mat-typography-level(inherit, 1.125, 400)
);
@include mat-core($custom-typography);

// Creazione delle palette colore necessarie al tema material
$light-primary: mat-palette($mat-primary, 500);
$light-accent: mat-palette($mat-accent, 500);
$light-warn: mat-palette($mat-warn, 500);
$light-theme: mat-light-theme($light-primary, $light-accent, $light-warn);
$theme: mat-light-theme($light-primary, $light-accent, $light-warn);

// Generazione dei temi material
@include angular-material-theme($light-theme);
@include covalent-theme($light-theme);
// @include media-center-theme($theme);
@include angular-core-theme($theme);

// Import misc
@import './styles/libraries-overrides/libraries-overrides';
@import './styles/shared/shared';
@import './styles/widget/widget';

@import './styles/base-page';
@import './styles/utility';

// Personalizzazione del layout nel contesto pubblico
// indipendentemente dal tema scelto
// @import './styles/layout-ctx-public/layout-ctx-public';

// il file theme-override è generato grazie allo stylePreprocessorOptions
// a partire dal file _theme-override presente nella cartella del tema usato
@import 'theme-override';

// TODO: deprecate in favour of proper mat styling
@mixin custom-button {
	font-family: $font-family;
	font-size: 1rem;
	padding: 1rem;
	border-radius: 0.5rem;
	border: 0;
	color: white;
	cursor: pointer;
}

.afu-reset-btn {
	@include custom-button;
	background-color: $accent;
}

.afu-upload-btn,
.afu-select-btn {
	@include custom-button;
	background-color: $primary;
}
