.dialog-fullscreen {
	max-width: none !important;
	width: 100vw;
	height: 100vh;
}
.dialog-medium {
	max-width: none !important;
	width: 80vw;
	height: auto;
}
.dialog-normal {
	width: 500px;
	height: auto;
}