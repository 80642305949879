@import 'var';
app-root {
    .mat-fab-button {
        position: fixed !important;
        bottom: 1rem;
        right: 1rem;
    }
    @media (min-width: 1024px) {
        .mat-fab-button {
            bottom: 1.5rem;
            right: 1.5rem;
        }
    }
    @include sectionBottomPositionGenerator('mat-fab-button');
}