/*
*  Theme name: Customer theme
*  This file contains variables different from var-base.scss
*/

@import '../../abstract/abstract';
@import '../var-base';

// Main palette
$primary: #0081C5;
$accent: #F3BF4F;
$secondary: #274759;

// menu highlight color
$color-menu-highlight: $primary;

// Create maps of color palette
$mat-primary: createPaletteColor($primary, 'mat-primary');
$mat-accent: createPaletteColor($accent, 'mat-accent');
$mat-secondary: createPaletteColor($secondary, 'mat-secondary');
$mat-warn: createPaletteColor($warn, 'mat-warn');
$mat-alert: createPaletteColor($alert, 'mat-alert');
$mat-success: createPaletteColor($success, 'mat-success');
$mat-info: createPaletteColor($info, 'mat-info');

// Custom Map Color : $gray
$mat-gray: (
	50: lighten($gray, 52%),
	100: lighten($gray, 37%),
	200: lighten($gray, 26%),
	300: lighten($gray, 12%),
	400: lighten($gray, 6%),
	500: $gray,
	600: darken($gray, 6%),
	700: darken($gray, 12%),
	800: darken($gray, 18%),
	900: darken($gray, 24%),
	contrast: (
		50: set-contrast(lighten($gray, 52%)),
		100: set-contrast(lighten($gray, 37%)),
		200: set-contrast(lighten($gray, 26%)),
		300: set-contrast(lighten($gray, 12%)),
		400: set-contrast(lighten($gray, 6%)),
		500: $light,
		600: set-contrast(darken($gray, 6%)),
		700: set-contrast(darken($gray, 12%)),
		800: set-contrast(darken($gray, 18%)),
		900: set-contrast(darken($gray, 24%))
	)
);

// Custom Font
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
$font-family-name: 'Work Sans', sans-serif;

// Header
$header-height: 120px;