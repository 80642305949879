/*
* Standard mixin structure
*
* /// name - description
* /// @param {type} $param [default] - description
* /// @output description
* /// @example example
* @mixin name($param){
* 	// code here
* }
*/

/// matShadow-z
/// @param {number (px)} $x
/// @param {number (px)} $y
/// @param {number (px)} $blur
/// @output property box-shadow
@mixin matShadow-z($x, $y, $blur) {
	box-shadow: $x $y $blur -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

/// text-ellipsis
/// @output add properties to handle ellipsis
@mixin text-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	min-width: 0;
}

/// centering
/// @param {static | relative | absolute | sticky | fixed} $position
/// @param {Y | X | null} $axis
/// @output add properties to center the element vertically, horizontally or both using transform
@mixin centering($position, $axis) {
	position: $position;
	@if $axis == 'Y' {
		$axisVal: 'Y(-50%)';
		top: 50%;
		-webkit-transform: translate#{$axisVal};
		-moz-transform: translate#{$axisVal};
		-o-transform: translate#{$axisVal};
		-ms-transform: translate#{$axisVal};
		transform: translate#{$axisVal};
	} @else if $axis == 'X' {
		$axisVal: 'X(-50%)';
		left: 50%;
		-webkit-transform: translate#{$axisVal};
		-moz-transform: translate#{$axisVal};
		-o-transform: translate#{$axisVal};
		-ms-transform: translate#{$axisVal};
		transform: translate#{$axisVal};
	} @else {
		$axisVal: '(-50%,-50%)';
		top: 50%;
		left: 50%;
		-webkit-transform: translate#{$axisVal};
		-moz-transform: translate#{$axisVal};
		-o-transform: translate#{$axisVal};
		-ms-transform: translate#{$axisVal};
		transform: translate#{$axisVal};
	}
}

/// columns - bootstrap
/// @param {number (px)} $mT [0] - margin top
/// @param {number (px)} $mR [0] - margin right
/// @param {number (px)} $mB [0] - margin bottom
/// @param {number (px)} $mL [0] - margin left
/// @output bootstrap column siste
@mixin col($mT: 0, $mR: 0, $mB: 0, $mL: 0, $pT: 0, $pR: 0, $pB: 0, $pL: 0) {
	.col,
	.col-1,
	.col-10,
	.col-11,
	.col-12,
	.col-2,
	.col-3,
	.col-4,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.col-9,
	.col-auto,
	.col-lg,
	.col-lg-1,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9,
	.col-lg-auto,
	.col-md,
	.col-md-1,
	.col-md-10,
	.col-md-11,
	.col-md-12,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9,
	.col-md-auto,
	.col-sm,
	.col-sm-1,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-auto,
	.col-xl,
	.col-xl-1,
	.col-xl-10,
	.col-xl-11,
	.col-xl-12,
	.col-xl-2,
	.col-xl-3,
	.col-xl-4,
	.col-xl-5,
	.col-xl-6,
	.col-xl-7,
	.col-xl-8,
	.col-xl-9,
	.col-xl-auto {
		margin: $mT $mR $mB $mL;
		padding: $pT $pR $pB $pL;
	}
}

/// backgroundColorGenerator
/// @param {Map} $colorMap - map two pairs value
/// @param {string} $name - name of the theme
/// @output a class that handle colors based on material palette
@mixin backgroundColorGenerator($colorMap, $name) {
	@each $key, $val in $colorMap {
		@if $key != 'contrast' {
			.background-#{$name}-#{$key} {
				background-color: map-get($colorMap, $key);
			}
		}
	}
}

/// utilityMarginGenerator
/// @output utility class to add margin exception
/// @example mt-4 { margin-top: 4px }
@mixin utilityMarginGenerator() {
	@each $letter, $word in $map-positioning {
		@for $i from 0 through 10 {
			&.m#{$letter}-#{$i*4} {
				margin-#{$word}: #{$i * 4}px !important;
			}
		}
	}
	&.m-0 {
		margin: 0 !important;
	}
}

/// utilityPaddingGenerator
/// @output utility class to add padding exception
/// @example pt-4 { padding-top: 4px }
@mixin utilityPaddingGenerator() {
	@each $letter, $word in $map-positioning {
		@for $i from 1 through 10 {
			&.p#{$letter}-#{$i*4} {
				padding-#{$word}: #{$i * 4}px !important;
			}
		}
	}
	&.p-0 {
		padding: 0 !important;
	}
}

/// gridDivider
/// @output utility class to add a divider
/// @example .grid-divider-lg ha uno spessore uguale a $gutter se il display è minore di 1200px di larghezza (vedere $breakpoint)
@mixin gridDivider() {
	@each $key, $val in $map-breakpoints {
		.grid-divider-#{$key} {
			margin: $gutter 0 0 0;
			width: 100%;
		}
		@media (min-width: $val) {
			.grid-divider-#{$key} {
				display: none;
			}
		}
	}
}

// $p-font-sizes: (
//   null  : (15px, 1.3), // default - mobile first
//   sm : 16px,
//   md: (17px, 1.4),
//   lg : (19px, 1.45)
// );

/// font-size
/// Responsive Typography Based on http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
/// @param {Map} $fs-map - map where key is the breakpoint and the value a font-size or a list with font-size and line-height value
/// @param {Map} $fs-breakpoints [$map-breakpoints] - global map for breakpoint-sass mixin
/// @output it generate responsive font based on a map
/// @example @include font-size($p-font-sizes);
@mixin font-size($fs-map, $fs-breakpoints: $map-breakpoints) {
	@each $fs-breakpoint, $fs-font-size in $fs-map {
		@if $fs-breakpoint == null {
			@include make-font-size($fs-font-size);
		} @else {
			// If $fs-font-size is a key that exists in
			// $fs-breakpoints, use the value
			@if map-has-key($fs-breakpoints, $fs-breakpoint) {
				$fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
			}
			@media screen and (min-width: $fs-breakpoint) {
				@include make-font-size($fs-font-size);
			}
		}
	}
}

/// make-font-size - Utility function for mixin font-size
/// @param {Map} $fs-font-size - values to declare font size and line height
@mixin make-font-size($fs-font-size) {
	// If $fs-font-size is a list, include
	// both font-size and line-height
	@if type-of($fs-font-size) == 'list' {
		font-size: nth($fs-font-size, 1);
		@if (length($fs-font-size) > 1) {
			line-height: nth($fs-font-size, 2);
		}
	} @else {
		font-size: $fs-font-size;
	}
}

/// navbar transition
@mixin navbar-transition($property: all) {
	transition: 0.3s $property;
}

/// list-reset - add properties to reset the list style
@mixin reset-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

/// text-decoration - it create an animation
/// @param {string} $color [$primary] -  on hover text color and line color
@mixin text-decoration($color: $primary) {
	$animation-time: 0.3s;

	position: relative;
	text-decoration: none;
	transition: color $animation-time;

	&:after {
		content: '';
		position: absolute;
		display: block;
		bottom: 0;
		left: 0;
		width: 0;
		height: 2px;
		background: $color;
		transition: $animation-time all ease-in-out;
	}

	&:hover,
	&:active,
	&.active {
		color: $color;

		&:after {
			width: 100%;
		}
	}
}

/*
* /// inputNumberHideArrow - hide arrow in input type number
* /// @include inputNumberHideArrow();
*/
@mixin inputNumberHideArrow() {
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}
}

/// sectionBottomPositionGenerator
/// genera le classi `.*-section-bottom-right` e `.*-section-bottom-left`
/// aventi relative proprietà di floating
@mixin sectionBottomPositionGenerator($prefix) {
	@each $key in ('right', 'left') {
        .#{$prefix}-section-bottom-#{$key} {
            float: #{$key};
            margin: $gutter 0 0 0;
        }
	}
}