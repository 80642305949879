// una volta dichiarati aggiungere il riferimento a
// frontend/src/app/enum/globalColor.enum.ts
html {
	--color-primary: #{$primary};
	--color-accent: #{$accent};
}

:root {
	// Main palette - Fallback
	--primary: #{$primary};
	--accent: #{$accent};
	--secondary: #{$secondary};

	// Light and dark
	--light: #{$light};
	--dark: #{$dark};
	--gray: #{$gray};
	--white: #{$white};

	// Semantic colors
	--warn: #{$warn};
	--alert: #{$alert};
	--success: #{$success};
	--info: #{$info};

	--color-menu-highlight: #{$color-menu-highlight};

	/*** 2.0 - Size ***/
	--width__sidenav: #{$width__sidenav};
	--height__main-toolbar: #{$height__main-toolbar};

	// Scala
	--base: #{$base};
	--gutter: #{$gutter};

	// Padding form wrapper
	--ptb-16: #{$ptb-16};
	--prl-32: #{$prl-32};
}