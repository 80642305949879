/*
*  This file contains generic CSS
*/

*,
*::before,
*::after {
	box-sizing: border-box;
}

html,
body {
	min-height: 100%;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Gestione della dimensione del font tramite la classe applicata all'html
// Material theme font-size base: 14px;
html.fontsize {
	&--normal {
		font-size: 14px !important;
	}
	&--medium {
		font-size: 16px !important;
	}
	&--big {
		font-size: 18px !important;
	}
}

:root {
	scroll-behavior: smooth;
	text-rendering: optimizeLegibility;
	box-sizing: border-box;
	.mat-drawer-container {
		background-color: $light;
	}
}

*:focus {
	outline: none;
}

a {
	color: inherit;
	&:hover,
	&:focus,
	&:visited {
		color: inherit;
	}
}

::selection {
	color: $light !important;
	background: $dark !important;
}

img {
	max-width: 100%;
	display: block;
}

app-root {
	// fixed position
	@each $v in $list-pos-vert {
		@each $h in $list-pos-hor {
			.fixed-#{$v}-#{$h} {
				position: fixed;
				#{$v}: $gutter;
				#{$h}: $gutter;
			}
		}
	}
}

// utility class

@include backgroundColorGenerator($mat-primary, primary);

* {
	@include utilityPaddingGenerator();
	@include utilityMarginGenerator();
}

// Customizzazione delle classi
// product-related__child e product-related__parent
// assegnate alle righe della tabella td-data-table
// per dare visibilità del rapporto gerarchico di alcuni prodotti

tr.product-related {
	$product-related-border-width: 2px;

	&__parent > td:first-child {
		background-repeat: repeat-y;
		background-position: center left;
		background: rgba($dark, 0);
		background: linear-gradient(
			90deg,
			rgba($dark, 1) 0%,
			rgba($dark, 1) $product-related-border-width,
			rgba($dark, 0) $product-related-border-width,
			rgba($dark, 0) 100%
		);
	}

	// mac fix - only first child with line
	// on mac it repeat each td
	&__child > td:first-child {
		background-repeat: repeat-y;
		background-position: center left;
		background: rgba($dark, 0.1);
		background: linear-gradient(
			90deg,
			rgba($dark, 1) 0%,
			rgba($dark, 1) $product-related-border-width,
			rgba($dark, 0.1) $product-related-border-width,
			rgba($dark, 0.1) 100%
		);
	}
	&__child > td:not(:first-child) {
		background: rgba($dark, 0.1);
	}
}
