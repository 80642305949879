@import 'var';

app-root {
	mat-accordion-wrapper {
		.mat-accordion {
			.mat-expansion-panel {
				&:last-of-type,
				&:first-of-type {
					border-bottom-right-radius: 0;
					border-bottom-left-radius: 0;
					border-top-right-radius: 0;
					border-top-left-radius: 0;
				}
				&:not([class*='mat-elevation-z']) {
					box-shadow: none;
				}
				// header
				.mat-expansion-panel-header {
					.mat-expansion-panel-header-title {
						align-items: center;
						h2 {
							margin: 0 0 0 $gutter;
							line-height: 40px;
						}
						.flex-span {
							padding: 0 0 0 $gutter;
						}
						.send-order {
							margin-right: $gutter * 2;
						}
					}
				}
				// main
				.mat-expansion-panel-body {
					padding: 0 24px;
				}
			}
		}
	}
}
