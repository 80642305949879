.b2c-article-detail {
	// background: map-get($map: $mat-accent, $key: 200);
    background: rgba($color: map-get($map: $mat-accent, $key: 200), $alpha: 0.5);

	@include decoration-ear($color: 'accent', $item-x: 2, $item-y: 2, $opacity: 0.2, $width: 200px, $space: 50px, $position: 'bottom-right');


    &__details{
        padding: 3rem 0;
    }

	&__related-products {
		background: map-get($map: $mat-accent, $key: 200);
		@include decoration-ear($color: 'accent', $item-x: 1, $item-y: 1, $opacity: 0.2, $width: 200px);
	}

    &__content__variant{
        background: transparent;
    }
    &__content{

		&--label{
			font-weight: 500;
			color: $secondary;
		}
    }
}
